.contact-container {
  place-items: center;
  .item1 {
    width: 65%;
  }

}

@media (max-width: 850px) {
  .contact-container {
    padding: 0 1rem;
    .item1, .item2 {
      padding: 1rem;
      width: 90%;
    }
   }
} 
////////// CARDS ////////// 
.cards-container {
  
  grid-template-columns: repeat(3, minmax(200px, 300px));
  grid-template-rows: auto;
  justify-content: center;
  margin: 2rem 0 3.5rem 0;
  grid-gap: 1.5rem;
  grid-template-areas: 
    'card1 card2 card3';
  .item1 {
    grid-area: card1;
  }
  .item2 {
    grid-area: card2;
  }
  .item3 {
    grid-area: card3;
  }
}

.card  {
    background-color: $primaryDark;
    border: none;
    height: 100%;
    max-width: 300px;
    .card-img-top {
      border-radius: 0;
    }
    .card-body {
      margin-top: -2rem;
    }
    .card-title {
      font-size: calc(20px + .2vw);
      background-color: $accentRed!important;
    }
    .card-text {
      margin-top: 1rem;
    }
    .card-footer {
      border: none;
    }
    
  }

  .lesson-card {
    width: 100%;
    max-width: 350px;
    height: auto;
    margin: 0;
    .card-text {
      margin: 0;
    }
    .card-title {
      background-color: $accentRed!important;
      width: 100%;
      padding: .25rem .3rem;
      border-radius: 10px;
    }
  }

  .card-img-overlay {
    display: grid;
    place-items: center;
  }
  
  .card-overlay-title {
    width: 75%;
    text-align: center;
    color: white;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -25%);
  }
  
  .card-img-overlay:hover .card-overlay-title {
    color: transparent;
    text-shadow: none;
    transition: h1 300ms linear;
    visibility: hidden;
  }
  
  .card-img-overlay {
    color: transparent;
    background: rgba(0,0,0,0.25);
    border-radius: 0;
  }
    
  .card-img-overlay a {
    color: transparent;
    background: transparent;
    border: transparent;
  }
    
  .card-img-overlay:hover {
    color: white;
    background: rgba(0, 0, 0, .8);
    transition: all 500ms ease;
  }
    
  .card-img-overlay:hover a {
    color: white;
    background: var(--accent-2);
    transition: all 400ms ease;
    border: 1px solid white;
  }
  
  .hide {
    visibility: hidden;
  }
  
  
////////// Bootstrap small column break (tablet) //////////
@media (max-width: 992px) {
  

}

////////// Bootstrap small column break (tablet) //////////
@media (max-width: 860px) {
  .cards-container {
    grid-template-columns: repeat(2, minmax(200px, 300px));
    grid-template-areas: 
      'card1 card2' 
      'card3 card3' ;
    .item3 {
      justify-self: center;
    }
  }

 
}

 ////////// Bootstrap small column break //////////
 @media (max-width: 680px) {
   .cards-container {
    grid-template-columns: minmax(200px, 300px);
    padding: .5rem;
    grid-template-areas: 
      'card1' 
      'card2' 
      'card3' ;
  }
} 

 ////////// GALLERY ////////// 

 .gallery-container {
    margin: 2rem 0 3.5rem 0;
    grid: auto-flow dense / repeat(2, 1fr);
    gap:  0.5rem;
   }
  
  .gallery-container img {
    width: 100%;
    transition: .4s all linear;
    &:hover {
        transform: scale(1.05);
        position: relative;
        z-index: 10;
    }
  }
 
 ////////// Bootstrap small column break //////////
@media (max-width: 768px) {
  .gallery-container {
    padding: .5rem;
   }
} 


 ////////// Bootstrap small column break //////////
 @media (max-width: 576px) {
  .gallery-container {
    grid: auto-flow dense / 1fr 1fr;
   }
} 
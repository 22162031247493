@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;700&family=Roboto+Slab&display=swap');
@import './variables';
@import './footer';
@import './header';
@import './cards';
@import './gallery';
@import './contact';

///////// GLOBAL /////////
* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

html, body {
  background-color: $primaryDark;
  color: $white;
  font-family: 'Poppins', sans-serif;
  scroll-behavior: smooth;
  height: 100%;
  font-size: 1rem;
}

main {
  padding: 2rem 0 5rem;
}

h1 {
  font-size: calc(34px + 1vw);
}

em{
  font-size: 14px;
}

.btn {
  background-color: $accentRed !important;
  &:hover {
    background-color: transparent !important;
    color: $white;
  }
}

.text-link {
  color: $white;
  font-style: italic;
  text-decoration: underline;
}

.padding {
  padding-bottom: 2rem;
}

.grid-container {
  display: grid;
  padding: 1rem 2rem;
}


/// PageTitle component styling ///
.page-title {
  text-align: center;
  padding: 0 2rem;
  h1 {
    padding-bottom: 0.8rem;
  }
  p {
    padding: 0.8rem 0;
  }
}

////////// Bootstrap small column break (tablet) //////////
@media (max-width: 768px) {
  
}

////////// Bootstrap small column break (tablet) //////////
@media (max-width: 576px) {
  .grid-container {
    padding: 1rem 0;
  }

  .padding {
    padding-bottom: 1rem;
  }

  /// PageTitle component styling ///
.page-title {
  padding: 0; 
}
}

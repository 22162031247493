////////// HEADER //////////
header { 
    border-bottom: 4px double $white;
    
}

.hero-image {
  background: linear-gradient(-45deg, rgba(0, 0, 0, 0.4), rgba(0,0,0,.4)), url(../images/hero.jpg) center bottom / cover no-repeat;
  position: relative;
  height: 45vh;
  min-height: 400px;
}

.hero-container {
    place-items: center;
    padding: 0;
    grid-template-columns: repeat(12, 1fr);
    grid-template-rows: auto;
    height: 45vh;
    min-height: 400px;
   
    
    .item1 {
        grid-column: 1 / 13;
        grid-row: 1 / 2 ;
        margin-top: 1.5rem;
        h1 {
            font-size: 6rem;
        }
        .header-logo {
            
            max-height: 350px;
            border: 3px solid $accentRed;
            border-radius: 15px;
          }
    }
    .item2 {   
        grid-column: 1 / 13;
        grid-row: 2 / 3;
        padding: 0 .5rem;
        max-height: 100px;
       
        button {
            padding: .5rem 1rem;
            
            
        }
    }
}



////////// NAVIGATION ////////// 
.nav-item {
    align-self: center;
}
.nav-item .active {
    color: $white;
    text-decoration: underline $accentRed;
}

a .social-link {
    color: $white;
    font-size: 3rem;
    margin: .2rem;
}
//Toggle social link OUTSIDE nav-toggler HIDDEN
.mobile-hidden {
    display: none;
}
//Toggle social link OUTSIDE nav-toggler VISIBLE
.mobile-visible {
    display: block;
}
  
.nav-link {
    font-size: 1.25rem;
    font-weight: 500;
    font-family: "Roboto Slab";
    .active {
        color: $white;
        text-decoration: underline $darkRed;
    }
    
}

.navbar-toggler {
    background-color: $accentRed!important;
    color: $white;
    opacity: .95;
    width: 150px;
}


////// Bootstrap sm width //////
@media (max-width: 576px) { 
    .hero-container{
        max-height: 45vh;
        .item1 {
            padding: 0;
            margin-top: 0;
            .header-logo {
                max-height: 285px;
              }
        }
        .item2 button {
            margin-top: 0;
            padding: .6rem 1rem;
        }
        
    }
    .nav {
        background-color: $accentRed !important;
    }
    .navbar {
        justify-content: center !important;
        background-color: $accentRed;
    }
    .nav-item {
        text-align: center;
        background-color: $accentRed;
        width: 150px;
        .active {
            display: none;
        }
    }

    .nav-link {
        font-size: 1.1rem;
        color: $white !important;
    }

    .navbar-toggler {
        color: $white !important;
        
    }

    a .social-link {
        font-size: 3.2rem;
        margin: 0;
    }

    //Toggle social link OUTSIDE nav-toggler VISIBLE
    .mobile-hidden {
        display: block;
    }
    //Toggle social link INSIDE nav-toggler HIDDEN
    .mobile-visible {
        display: none;
    }

}


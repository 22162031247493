////////// VARIABLES //////////
$white : #fff;

$darkRed : #a10606;
$accentRed : #cc3608;

$primaryDark : #051944;
$secondaryDark : #005fad;

$primaryDark : #005fad;
$secondaryDark : #051944;

